import React from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { StepLayout } from '../components'
import { changeInput } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { DEF_PROPS, MASK } from '@constants/inputs'
import { MaskedTextField } from '@components/atoms'

const PersonalData = () => {
    const { name, cpf, rg, dateOfBirth, phone, address, email, error } = useSelector(state => state.sub.profile)
    const dispatch = useDispatch()
    
    const handleChange = id => event => {
        dispatch(changeInput('profile', id, event.target.value))
    }

    return (
        <StepLayout title='Informe seus dados pessoais' description='Precisamos de algumas informações suas para
            confirmar sua inscrição.' image='ill_personal_information'
        >
            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 }>
                    <TextField { ...DEF_PROPS.name } error={ error.includes('name') } helperText={ error.includes('name') ? 'Preencha um nome válido' : '' }
                        value={ name } onChange={ handleChange('name') }
                    />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                    <MaskedTextField { ...DEF_PROPS.cpf } error={ error.includes('cpf') } mask={ MASK.cpf } value={ cpf } onChange={ handleChange('cpf') } 
                        helperText={ error.includes('cpf') ? 'Preencha um CPF válido' : '' }
                    />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                    <TextField { ...DEF_PROPS.rg } error={ error.includes('rg') } value={ rg } helperText={ error.includes('rg') ? 'Preencha um RG válido' : '' } 
                        onChange={ handleChange('rg') } 
                    />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                    <MaskedTextField { ...DEF_PROPS.dateOfBirth } autoComplete='bday' error={ error.includes('dateOfBirth') } mask={ MASK.dateOfBirth }
                        value={ dateOfBirth } onChange={ handleChange('dateOfBirth') } helperText={ error.includes('dateOfBirth') ? 'Preencha uma data válida' : '' }
                    />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                    <MaskedTextField { ...DEF_PROPS.phone } error={ error.includes('phone') } mask={ MASK.phone(phone) }
                        value={ phone } onChange={ handleChange('phone') } helperText={ error.includes('phone') ? 'Preencha um telefone válido' : '' }
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <TextField { ...DEF_PROPS.address } error={ error.includes('address') } value={ address } onChange={ handleChange('address') } 
                        helperText={ error.includes('address') ? 'Preencha um endereço válido' : '' }
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <TextField { ...DEF_PROPS.email } error={ error.includes('email') } value={ email } onChange={ handleChange('email') } 
                        helperText={ error.includes('email') ? 'Preencha um email válido' : '' }
                    />
                </Grid>
            </Grid>
        </StepLayout>
    )
}

export default PersonalData