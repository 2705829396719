import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import IconButton from '@mui/material/IconButton'
import styles from './styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

const isValidImgSize = (width, heigth) => {
    return (width >= 3000 && heigth >= 4000) || (width >= 4000 && heigth >= 3000)
} 

const FilePreview = props => {
    const { file, onDelete, checkSize, isMobile } = props
    const nameSplit = file.name.split('.')
    const fileExt = nameSplit[nameSplit.length - 1].toUpperCase()
    const fileName = nameSplit[0].slice(0, isMobile ? 18 : 100)
    const [imgUrl, setImgUrl] = useState('')
    const [imgWarn, setImgWarn] = useState(false)
    
    useEffect(() => {
        if (file.type.startsWith('image/')) {
            try {
                const objectURL = window.URL.createObjectURL(file)
                const img = new Image(60, 60)
                img.onload = () => {
                    setImgUrl(objectURL)
                    setImgWarn(!isValidImgSize(img.naturalWidth, img.naturalHeight))
                    window.URL.revokeObjectURL(file)
                }
                img.src = objectURL
            }
            catch (e) {
                try {
                    const fileReader = new FileReader()
                    fileReader.onload = e => {
                        const img = new Image(60, 60)
                        img.onload = () => {
                            setImgUrl(e.target.result)
                            setImgWarn(!isValidImgSize(img.naturalWidth, img.naturalHeight))
                        }
                        img.src = e.target.result
                    }
                    fileReader.readAsDataURL(file)
                }
                catch (e) {
                  console.log(`Erro ao mostrar preview da imagem: ${ e }`)
                }
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file.name])

    return (
        <>
            <Box sx={ styles.background }>
                <div style={ styles.infos }>
                    <div style={ styles.imgArea }>
                        {  imgUrl ?
                            <img src={ imgUrl } alt='Foto anexada' style={ styles.img }/> :
                            <div style={ styles.ext }>
                                <Typography align='center'>{ fileExt }</Typography>
                            </div>
                        }
                    </div>
                    <div>
                        <Typography variant='subtitle1' color='primary' sx={{ fontWeight: 'bold' }}>
                            { fileName.length < nameSplit[0].length ? fileName + '...' : fileName }
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary'>
                            { (file.size / 1000000).toFixed(2) } MB • { fileExt }
                        </Typography>
                    </div>
                </div>
                { onDelete &&
                    <IconButton aria-label="Deletar imagem anexada" onClick={ onDelete }>
                        <DeleteIcon />
                    </IconButton>
                }
            </Box>
            { checkSize && imgWarn &&
                <Alert severity='warning' variant='outlined'>
                    Sua foto não possui o tamanho mínimo de 4000px do lado maior e 3000px no lado menor, você
                    deseja continuar mesmo assim?
                </Alert>
            }
        </>
    )
}
export default FilePreview