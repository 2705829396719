import React, { useRef } from 'react'
import Button from '@mui/material/Button'
import { StepLayout } from '../components'
import GetAppIcon from '@mui/icons-material/GetAppRounded'
import AttachFileIcon from '@mui/icons-material/AttachFileRounded'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/AddRounded'
import { useDispatch, useSelector } from 'react-redux'
import { authPeopleUnderAge, authPeople } from '@assets/files'
import { FilePreview } from '@pagesComponents/subscription'
import { validateFiles } from '../../utils'
import styles from './styles'
import { changeValue } from '../../actions'

const PeopleAuths = () => {
    const inputRef = useRef(null)
    const auths = useSelector(state => state.sub.auths.peoples)
    const isMobile = useSelector(state => state.sub.isMobile)
    const dispatch = useDispatch()

    const handleChangeFiles = event => {
        event.preventDefault()
        const validFiles = validateFiles(inputRef.current.files, 30, 30)

        if (validFiles) changeFiles([...auths, ...validFiles])
        inputRef.current.value = ''
    }
    
    const changeFiles = files => {
        dispatch(changeValue('peoples', files, 'auths'))
        dispatch(changeValue('warning', files.length ? '' : 'Não esqueça as autorizações! Ou clique em "Não" caso não seja possível identificar pessoas na foto', 'stepper'))
    }

    return (
        <StepLayout title='É possível identificar alguma pessoa na foto?' description='Caso seja possível identificar 
            pessoas em sua foto, você precisará anexar a autorização de uso de imagem de CADA uma delas, do contrário, 
            pode pular esta etapa clicando em "Não". Se a pessoa for menor de idade, utilize a autorização "Menor", do contrário, utilize a autorização "Adulto"'
            image='ill_people'
        >
            <Button startIcon={ <GetAppIcon/> } variant='outlined' download='Autorização de uso de imagem - Adulto.pdf' target='_blank'
                href={ authPeople } sx={{ mr: .5, mb: 1 }}
            >
                Baixar autorização (adulto)
            </Button>
            <Button startIcon={ <GetAppIcon/> } variant='outlined' download='Autorização de uso de imagem - Menor.pdf' target='_blank'
                href={ authPeopleUnderAge } sx={{ mb: 1 }}
            >
                Baixar autorização (menor)
            </Button>
            <Button startIcon={ <AttachFileIcon/> } variant='outlined' onClick={ () => inputRef.current.click() } sx={{ mb: 2 }}>
                { auths.length > 0 ? 'Anexar mais arquivos' : 'Anexar autorizações' }
            </Button>
            { auths.map(file =>
                <FilePreview key={ file.name } file={ file } isMobile={ isMobile } onDelete={ () => changeFiles(auths.filter(el => el.name !== file.name)) }/>
            )}
            { auths.length > 0 && 
                <div style={ styles.iconButtonPeopleAuth }>
                    <IconButton color='primary' onClick={ () => inputRef.current.click() }>
                        <AddIcon fontSize='large' sx={ styles.iconPeopleAuth }/>
                    </IconButton>
                </div>
            }
            <input ref={ inputRef } type='file' accept='application/pdf,image/*' multiple style={{ display: 'none' }} onChange={ handleChangeFiles }/>
        </StepLayout>
    )
}
export default PeopleAuths