import React, { useRef } from 'react'
import Button from '@mui/material/Button'
import { StepLayout } from '../components'
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'
import { useDispatch, useSelector } from 'react-redux'
import { validateFiles } from '@pagesComponents/subscription/utils'
import { FilePreview } from '@pagesComponents/subscription'
import { changeValue } from '@pagesComponents/subscription/actions'

const UploadPhoto = () => {
    const inputRef = useRef(null)
    const photo = useSelector(state => state.sub.photo.file)
    const isMobile = useSelector(state => state.sub.isMobile)
    const dispatch = useDispatch()
    
    const handleChangeFile = event => {
        event.preventDefault()
        const validFiles = validateFiles(inputRef.current.files, 1, 30)

        if (validFiles) changeFile(validFiles[0])
        inputRef.current.value = ''
    }
    
    const changeFile = file => {
        dispatch(changeValue('file', file, 'photo'))
        dispatch(changeValue('warning', file ? '' : 'Não esqueça de anexar a sua foto', 'stepper'))
    }
    
    return (
        <StepLayout title='Envie sua foto' image='ill_photo' description='Chegou o momento mais importante, hora de enviar sua foto,
            lembrando que ela precisa ter um tamanho mínimo de 4000px do lado maior e 3000px no lado menor, em 300 dpi.'
        >
            <Button startIcon={ <AttachFileRoundedIcon/> } sx={{ mb: 2 }}  onClick={ () => inputRef.current.click() } variant='outlined'>
                { photo ? 'Alterar foto' : 'Anexar foto' }
            </Button>
            { photo && <FilePreview checkSize file={ photo } isMobile={ isMobile } onDelete={ () => changeFile(null) }/> }
            <input ref={ inputRef } type='file' accept='image/*' multiple={ false } style={{ display: 'none' }} onChange={ handleChangeFile }/>
        </StepLayout>
    )
}
export default UploadPhoto