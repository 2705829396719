const styles = {
    background: {
        width: '100%',
        px: {
            md: 9,
            xs: 2,
        },
    },
    stepBackground: {
        padding: 4,
        pl: {
            md: 6,
            xs: 2
        }
    },
    stepMessages: {
        width: { md: '50%', xs: '100%' }
    }
}

export default styles