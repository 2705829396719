import getReducer from '@utils/getReducer'
import dayjs from 'dayjs'
import { changeError, changeValue } from './actions'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export function isUnderAge(date, format) {
    return dayjs().diff(dayjs(date, format), 'year') < 18   // dayjs('2022-01-18').diff(dayjs('2004-01-18'), 'year') // 18
}

export function validateStep(dispatch, step) {
    let isValid = true
    const isUnderAge = getReducer('sub', 'profile', 'underAge')
    const currentStep = step + (isUnderAge ? 0 : 1)

    if (step === 1) isValid = validatePersonalInfo(dispatch)
    if (isUnderAge && step === 2) isValid = validateUnderAgeAuth(dispatch)
    if (currentStep === 4) isValid = validatePhotoInfos(dispatch)
    if (currentStep === 5) isValid = validatePeoplesAuth(dispatch)
    if (currentStep === 6) isValid = validatePhotoFile(dispatch)
    
    return isValid
}

function validatePersonalInfo(dispatch) {
    let valid = true
    const ids = ['name', 'cpf', 'rg', 'dateOfBirth', 'phone', 'address', 'email']
    const state = getReducer('sub', 'profile')

    ids.forEach(id => {
        if (!state[id]) {
            dispatch(changeError('profile', id, true))
            valid = false
        }
    })

    return valid && state.error.length === 0
}

function validateUnderAgeAuth(dispatch) {
    const file = getReducer('sub', 'auths', 'underAge')
    
    dispatch(changeValue('warning', file ? '' : 'Não esqueça de anexar a autorização preenchida', 'stepper'))

    return Boolean(file)
}

function validatePhotoInfos(dispatch) {
    let valid = true
    const ids = ['title', 'place',]
    const state = getReducer('sub', 'photo')

    ids.forEach(id => {
        if (!state[id]) {
            dispatch(changeError('photo', id, true))
            valid = false
        }
    })

    return valid && state.error.length === 0
}

function validatePeoplesAuth(dispatch) {
    const peopleAuths = getReducer('sub', 'auths', 'peoples')

    if (peopleAuths.length === 0) {
        dispatch(changeValue('warning', 'Não esqueça as autorizações! Ou clique em "Não" caso não seja possível identificar pessoas na foto', 'stepper'))
    }

    return peopleAuths.length !== 0
}

function validatePhotoFile(dispatch) {
    const photo = getReducer('sub', 'photo', 'file')
    
    dispatch(changeValue('warning', photo ? '' : 'Não esqueça de anexar a sua foto', 'stepper'))

    return Boolean(photo)
}

export function validateCpf(value) {
    const cpf = value.replace(/\D/g, '').split('')
    let v1 = 0
    let v2 = 0
    let aux = false

    for (let i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] !== cpf[i])  aux = true
    }

    if (!aux) return false

    for (let i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += parseInt(cpf[i]) * p
    }

    v1 = ((v1 * 10) % 11)
    if (v1 === 10) v1 = 0
    if (v1.toString() !== cpf[9]) return false

    for (let i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += parseInt(cpf[i]) * p
    }

    v2 = ((v2 * 10) % 11)
    if (v2 === 10) v2 = 0
    if (v2.toString() !== cpf[10]) {
        return false
    } else {
        return true
    }
}

/**
 * Validate file size and quantity
 * 
 * ---
 * 
 * @param { File[] } files - Array of Files
 * @param { number } max - Max number of files to attach (Default to `10`)
 * @param {*} maxSize - Max file size of each file (Default to `15`)
 * @returns { File[] } Returns the list of valid files or null
 */
export function validateFiles(files, max=10, maxSize=15) {
    if (files && files.length > 0) {
        if (files.length > max) {
            alert(`Você não pode anexar mais que ${ max } autoriza${ max > 1 ? 'ções' : 'ção' }! Caso necessário entre em contato por e-mail`)
            return null
        }

        const largeFiles = []

        for (let i = 0; i <files.length; i++) {
            if (files[i].size > (maxSize * 1024 * 1024)) {
                largeFiles.push(files[i].name)
            }
        }

        if (largeFiles.length > 0) {
            alert(`Você não pode anexar arquivos com mais de ${ maxSize } MB, por isso os seguintes arquivos foram ignorados: ${ largeFiles.join(' - ') }`)
            const filtered = Array.from(files).filter(file => !largeFiles.find(el => el === file.name))
            return filtered.length > 0 ? filtered : null
        }

        return Array.from(files)
    }

    return null
}