import React from 'react'
import { StepLayout } from '../components'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

const Category = () => {
    const { profile, photo } = useSelector(state => state.sub)

    return (
        <StepLayout title='Confirmar inscrição' description='Não se esqueça de revisar os dados antes de confirmar sua inscrição,
            pois após confirmada, as informações não poderão ser alterada' image='ill_finish'
        >
            <div>
                <Typography variant='subtitle1'><b>Nome:</b> { profile.name }</Typography>
                <Typography variant='subtitle1'><b>CPF:</b> { profile.cpf }</Typography>
                <Typography variant='subtitle1'><b>RG:</b> { profile.rg }</Typography>
                <Typography variant='subtitle1'><b>Data de nascimento:</b> { profile.dateOfBirth }</Typography>
                <Typography variant='subtitle1'><b>Telefone:</b> { profile.phone }</Typography>
                <Typography variant='subtitle1'><b>Endereço:</b> { profile.address }</Typography>
                <Typography variant='subtitle1'><b>E-mail:</b> { profile.email }</Typography>
                <Typography variant='subtitle1'><b>Título da foto:</b> { photo.title }</Typography>
                <Typography variant='subtitle1'><b>Local da foto:</b> { photo.place }</Typography>
            </div>
        </StepLayout>
    )
}

export default Category