import React from 'react'
import ROUTES from '@constants/routes'
import Text from './Text'
import PersonalInfos from './PersonalInfos'
import UnderAgeAuth from './UnderAgeAuth'
import Category from './Category'
import PhotoInfos from './PhotoInfos'
import PeopleAuths from './PeopleAuths'
import UploadPhoto from './UploadPhoto'
import Summary from './Summary'

const STEPS = underAge => [
    <Text title='Leia o edital do concurso' button={{ label: 'Ver edital', path: ROUTES.rules.path }} image='ill_reading'>
        No edital você encontra todas as informações referentes ao concurso, por isso, leia com atenção.
    </Text>,
    <PersonalInfos/>,
    underAge ? <UnderAgeAuth/> : null,
    <Category/>,
    <PhotoInfos/>,
    <PeopleAuths/>,
    <UploadPhoto/>,
    <Text title='Precisamos da sua autorização' image='ill_accept'>
        Autorizo o Sicoob Credisulca a expor/utilizar a fotografia anexada acima, sem qualquer prova e por prazo
        indeterminado, em eventos, publicações e materiais de divulgação da Cooperativa e do Concurso de Fotografia,
        sempre com a preservação dos direitos autorais morais, ou seja, ter sempre atrelado o nome do autor a sua
        obra. Declaro ainda que sou o legítimo titular dos direitos autorais e assumo a integral responsabilidade
        pela originalidade da obra. Declaro que li integralmente o edital do 3º Concurso de Fotografia Foco no
        Cooperativismo e estou de acordo com todo o conteúdo descrito, ciente de todas as regras.
    </Text>,
    <Summary/>
].filter(el => el)

export default STEPS