export const MASK = {
    phone: value => [...['(', /[1-9]/, /\d/, ')', ' '], ...('2345'.includes(value?.replace(/\D/g, '')[2]) ? [] : [/\d/]), ...[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]],
    cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/,/\d/],
    dateOfBirth: [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
}

export const LENGTH = {
    name: { min: 5, max: 150 },
    phone: { min: 10, max: 15 },
    email: { min: 3, max: 150 },
    cpf: { min: 11, max: 14 },
    rg: { min: 2, max: 30 },
    address: { min: 5, max: 200 },
    dateOfBirth: { min: 8, max: 10 },
    photoTitle: { min: 2, max: 120 },
    photoPlace: { min: 2, max: 120 },
}

export const REGEX = {
    email: '^([A-Za-z0-9_\\-\\.])+\\@([A-Za-z0-9_\\-\\.])+\\.([A-Za-z]{2,4})$',
    name: `^([a-zA-Z\\u00C0-\\u00FF ]){${ LENGTH.name.min },${ LENGTH.name.max }}$`,
    phone: '^(([(]([0-9]{2})[)])|([0-9]{2}))[ ]?[0-9]?[ ]?[0-9]{4}(s|[-])?[0-9]{4}$'
}

export const DEF_PROPS = {
    name: {
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "Nome completo",
        autoComplete: 'name',
        inputProps: { maxLength: LENGTH.name.max, type: 'text' }
    },
    phone: {
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "Telefone",
        autoComplete: 'tel',
        inputProps: { maxLength: LENGTH.phone.max, type: 'text', inputMode: 'numeric' }
    },
    email: {
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "E-mail",
        autoComplete: 'email',
        inputProps: { maxLength: LENGTH.email.max, type: 'text' }
    },
    cpf: {
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "CPF", 
        inputProps: { maxLength: LENGTH.cpf.max, type: 'text' }
    },
    dateOfBirth: {
        autoComplete: 'bday',
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "Data de nascimento", 
        inputProps: { maxLength: LENGTH.dateOfBirth.max, type: 'text' }
    },
    rg: {
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "RG", 
        inputProps: { maxLength: LENGTH.rg.max, type: 'text' }
    },
    address: {
        required: true, 
        variant: "outlined",
        autoComplete: 'street-address',
        fullWidth: true,
        label: "Endereço", 
        inputProps: { maxLength: LENGTH.address.max, type: 'text' }
    },
    photoTitle: {
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "Titulo da foto",
        inputProps: { maxLength: LENGTH.photoTitle.max, type: 'text' }
    },
    photoPlace: {
        required: true, 
        variant: "outlined",
        fullWidth: true,
        label: "Onde foi fotografada",
        inputProps: { maxLength: LENGTH.photoPlace.max, type: 'text' }
    },
}