import React from 'react'
import Dialog from '@mui/material/Dialog'
import styles from './styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useDispatch, useSelector } from 'react-redux'
import useImage from '@hooks/useImage'
import { changeValue } from '../actions'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SUB_CLEAN } from '@constants/actionTypes'

const SuccessDialog = () => {
    const email = useSelector(state => state.sub.profile.email)
    const visible = useSelector(state => state.sub.dialogSuccess)
    const subscriptionID = useSelector(state => state.sub.subscriptionID)
    const isMobile = useSelector(state => state.sub.isMobile)
    const imageData = useImage('ill_success')  
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(changeValue('dialogSuccess', false))
        setTimeout(() => {
            dispatch({ type: SUB_CLEAN })
        }, 250)
        
    }
    
    return (
        <Dialog fullWidth maxWidth='md' onClose={ handleClose } aria-labelledby="Mensagem de inscrição realizada" open={ visible }>
            <div style={ styles.background }>
                <Grid container spacing={ 3 } alignItems='center' justify='space-between'>
                    <Grid item xs={ 12 } sm={ 5 }>
                        <GatsbyImage image={ getImage(imageData) } alt='Ilustração de duas pessoas'/>
                    </Grid>
                    <Grid item xs={ 12 } sm={ 7 } align={ isMobile ? 'center' : 'left' }>
                        <Typography color='primary' variant='h4' sx={ styles.title }>
                            Inscrição realizada!
                        </Typography>
                        <Typography color='secondary' variant={ isMobile ? 'body2' : 'body1' }>
                            Obrigado por participar do Foco no Cooperativismo. O comprovante de inscrição foi enviado para o e-mail:
                        </Typography>
                        <Typography variant={ isMobile ? 'body2' : 'body1' } sx={{ fontWeight: 'bold', mb: 2 }}>
                            { email }
                        </Typography>
                        <Typography color='secondary' variant={ isMobile ? 'body2' : 'body1' }>
                            Você também pode anotar o ID da sua inscrição:
                        </Typography>
                        <div style={ styles.subId }>
                            <Typography color='secondary' variant={ isMobile ? 'body2' : 'body1' } align='center'>
                                { subscriptionID || 'Indisponível' }
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <div style={ styles.button }>
                    <Button variant='contained' disableElevation onClick={ handleClose } size={ isMobile ? 'small' : 'medium' }
                        color='primary'
                    >
                        Fechar
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
export default SuccessDialog