import React from 'react'
import styles from './styles'
import Typography from '@mui/material/Typography'
import useImage from '@hooks/useImage'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'

const StepLayout = props => {
    const { children, title, description, image } = props
    const imageData = useImage(image)
    const isMobile = useSelector(state => state.sub.isMobile)

    return (
        <div style={ styles.stepBackground }>
            <Box sx={ styles.stepTexts }>
                <Typography sx={ styles.stepTitle } variant='h4' color='primary'>
                    { title }
                </Typography>
                <Typography color='secondary' sx={ styles.stepDescription }>
                    { description }
                </Typography>
                { children }
            </Box>
            { !isMobile &&
                <div style={ styles.stepImage }>
                    <GatsbyImage image={ getImage(imageData) } alt='Ilustração'/>
                </div>
            }
        </div> 
    )
}

export default StepLayout