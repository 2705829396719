import React from 'react'
import MuiStepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Typography from '@mui/material/Typography'
import styles from './styles'
import STEPS from './steps'
import { useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import { Buttons } from './components'
import Box from '@mui/material/Box'

const Stepper = () => {
    const { index, warning, loading, error } = useSelector(state => state.sub.stepper)
    const underAge = useSelector(state => state.sub.profile.underAge)
    const stepsList = STEPS(underAge)
    const isLastStep = index === stepsList.length - 1

    return (
        <Box sx={ styles.background }>
            <MuiStepper activeStep={ index } orientation="vertical">
                { stepsList.map((comp, i) => (
                    <Step key={ i.toString() }>
                        <StepLabel/>
                        <StepContent sx={ styles.stepBackground }>
                            <Typography variant='h6' color='textSecondary'>
                                { `${ isLastStep ? 'Último' : index + 1 + 'º' } Passo` }
                            </Typography>
                            { comp }
                            <Box sx={ styles.stepMessages }>
                                { warning && <Alert variant='filled' severity="warning">{ warning }</Alert>}
                                { error && <Alert variant='filled' severity="error">{ error }</Alert>}
                                { loading && <Alert variant='filled' severity="info">{ loading }</Alert>}
                            </Box>
                            <Buttons step={ index } underAge={ underAge } loading={ loading } isLastStep={ isLastStep }/>
                        </StepContent>
                    </Step>
                ))}
            </MuiStepper>
        </Box>
    )
}

export default Stepper