import React from 'react'
import TextField from '@mui/material/TextField'
import { StepLayout } from '../components'
import { useSelector, useDispatch } from 'react-redux'
import { DEF_PROPS } from '@constants/inputs'
import { changeInput } from '@pagesComponents/subscription/actions'

const PhotoInfos = () => {
    const { title, place, error } = useSelector(state => state.sub.photo)
    const dispatch = useDispatch()
    
    const handleChange = id => event => {
        dispatch(changeInput('photo', id, event.target.value))
    }

    return (
        <StepLayout title='Sobre a foto...' description='Precisamos saber qual o título que você quer dar para sua foto e
            onde ela foi tirada' image='ill_photographer'
        >
            <TextField { ...DEF_PROPS.photoTitle } error={ error.includes('title') } value={ title } onChange={ handleChange('title') }
                helperText={ error.includes('title') ? 'Preencha um título válido' : '' } 
            />
            <div style={{ height: 20 }}/>
            <TextField { ...DEF_PROPS.photoPlace } error={ error.includes('place') } value={ place } onChange={ handleChange('place') }
                helperText={ error.includes('place') ? 'Preencha um nome válido' : '' } 
            />
        </StepLayout>

    )
}

export default PhotoInfos