const styles = {
    background: {
        padding: { 
            md: 1.2,
            xs: 1
        },
        borderRadius: 2,
        marginBottom: 1,
        border: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    infos: {
        display: 'flex',
        alignItems: 'center',
    },
    imgArea: {
        backgroundColor: '#00ae9d',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 8,
        marginRight: 15,
        overflow: 'auto',
        minWidth: 50,
        minHeight: 50,
    },
    ext: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center'
    },
    img: {
        width: 50,
        height: 50,
        objectFit: 'cover'
    }
}

export default styles