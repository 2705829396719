const styles = {
    bannerArea: {
        position: 'relative',
        height: {
            md: '100vh',
            xs: '40vh'
        },
        maxHeight: {
            md: '100vh',
            xs: '40vh'
        },
    },
    bannerImg: {
        height: '100%',
        width: '100%'
    },
    bannerText: {
        fontSize: {
            xs: 17,
            md: 26
        },
        color: '#fff',
        position: 'absolute',
        width: {
            md: '40%',
            xs: '70%'
        },
        right: { 
            md: 25,
            xs: 5
        },
        bottom: {
            md: 30,
            xs: 12
        },
    },
    descriptionArea: {
        padding:  {
            md: '6vw',
            xs: 3
        }
    },
    title: {
        mt: 4,
        fontWeight: 'bold'
    },
    commentArea: {
        borderLeftColor: 'primary.main',
        borderLeftWidth: 4,
        borderLeftStyle: 'solid',
        borderRadius: 1,
        backgroundColor: '#f4f4f4',
        padding: 2,
        paddingLeft: 3
    },
    downloadTitle: {
        fontWeight: 'bold',
        mb: 1
    },
    buttonAuth: {
        mb: {
            md: 1,
            xs: 1
        }
    }
}

export default styles