import React from 'react'
import Typography from '@mui/material/Typography'
import styles from './styles'
import Grid from '@mui/material/Grid'
import Button from "@mui/material/Button"
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'
import { authUnderAge, authPeople, authPeopleUnderAge } from '@assets/files'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Box from '@mui/material/Box'
import useImage from '@hooks/useImage'

const PageHeader = () => {
    const image = useImage('subscription_banner')

    return (
        <div>
            <Box sx={ styles.bannerArea }>
                <div style={ styles.bannerImg }>
                    <GatsbyImage style={{ width: '100%', height: '100%' }} image={ getImage(image) } alt='Foto do concurso' itemProp='image'/>
                </div>
                <Typography variant='h5' component='h3' sx={ styles.bannerText }>A arte da fotografia é
                    uma ferramenta para um <b>mundo sustentável</b>, participe e faça desse planeta um lugar melhor!
                </Typography>
            </Box>
            <Box sx={ styles.descriptionArea }>
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 } md={ 8 }>
                        <Box sx={ styles.commentArea }>
                            <Typography color='secondary'>
                                Lembrando que, caso seja possível identificar alguma pessoa em sua foto, você
                                precisará anexar uma autorização de uso de imagem de cada uma delas, e caso você
                                tenha menos de 18 anos, precisará anexar uma autorização assinada pelos seus pais
                                para poder participar do concurso. Ao lado você pode fazer o download das autorizações
                                conforme necessário.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 } align='center'>
                        <Typography variant='h5' color='primary' sx={ styles.downloadTitle }>
                            Download das autorizações
                        </Typography>
                        <Button startIcon={ <GetAppRoundedIcon/> } size='medium' variant='outlined' href={ authPeople } sx={ styles.buttonAuth }
                            download='Autorização de uso de imagem - Adulto.pdf' target='_blank'
                        >
                            Autorização uso de imagem - Adulto
                        </Button>
                        <Button startIcon={ <GetAppRoundedIcon/> } size='medium' variant='outlined' href={ authPeopleUnderAge } sx={ styles.buttonAuth }
                            download='Autorização de uso de imagem - Menor.pdf' target='_blank'
                        >
                            Autorização uso de imagem - Menor
                        </Button>
                        <Button startIcon={ <GetAppRoundedIcon/> } size='medium' download='Autorização menor de idade.pdf' target='_blank' variant='outlined' 
                            href={ authUnderAge } sx={ styles.buttonAuth } 
                        >
                            Autorização para menores de idade
                        </Button>
                    </Grid>
                </Grid>
                <div style={{ height: 35 }}/>
                <Typography sx={ styles.title } variant='h4' color='primary' align='center'>
                    Iniciar inscrição
                </Typography>
            </Box>
        </div>
    )
}
export default PageHeader