import React, { useEffect } from 'react'
import styles from './styles'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import { goNextStep, changeValue } from '@pagesComponents/subscription/actions'
import { useDispatch } from 'react-redux'

const Button = props => (
    <MuiButton { ...props } style={ styles.button } disableElevation color='primary'>
        { props.label }
    </MuiButton>
)

const Buttons = props => {
    const { step, isLastStep, loading, underAge } = props
    const dispatch = useDispatch()

    const handleNext = () => {
        dispatch(goNextStep(step, isLastStep))
    }

    useEffect(() => {
        const handleKeyPress = event => {
            if (event.key === 'Enter') handleNext()
        }

        document.addEventListener('keydown', handleKeyPress)  
        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBack = () => {
        dispatch(changeValue('stepper', { index: step - 1, warning: '', loading: '', error: ''}))
    }

    const jumpStep = () => {
        dispatch(changeValue('index', step + 1, 'stepper'))
        dispatch(changeValue('warning', '', 'stepper'))
    }

    const handleDeclineTerms = () => {
        dispatch(changeValue('warning', 'Você precisa aceitar os termos se quiser continuar com a inscrição', 'stepper'))
    }

    return (
        <Box sx={ styles.buttons }>
            { step !== 0 && <Button onClick={ handleBack } label='Voltar' disabled={ Boolean(loading) }/> }
            { step === (underAge ? 5 : 4) && <Button onClick={ jumpStep } label='Não' variant='outlined'/> }
            { step === (underAge ? 7 : 6) && <Button onClick={ handleDeclineTerms } label='Não concordo' variant='outlined'/>}
            <Button onClick={ handleNext } disabled={ Boolean(loading) } variant='contained'
                label={ Boolean(loading) ? 'Carregando... Aguarde' : step === (underAge ? 7 : 6) ? 'Concordo' : isLastStep ? 'Confirmar inscrição' : 'Próximo' }
            />
        </Box>
    )
}
export default Buttons