import React from 'react'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { StepLayout } from '../components'
import { CURRENT_EDITION } from '@constants/editions'
import { useDispatch, useSelector } from 'react-redux'
import { changeValue } from '@pagesComponents/subscription/actions'

const Category = () => {
    const category = useSelector(state => state.sub.photo.category)
    const dispatch = useDispatch()

    const handleChange = event => {
        dispatch(changeValue('category', event.target.value, 'photo'))
    }

    return (
        <StepLayout title='Qual a categoria?' description='Nosso concurso é dividido em 3 categorias,
            escolha aquela que melhor define sua obra' image='ill_choice'
        >
            <FormControl component="fieldset">
                <RadioGroup aria-label="Selecionar a categoria da foto" name="category" value={ category } onChange={ handleChange }>
                    { Object.values(CURRENT_EDITION.categories).map(category => (
                        <FormControlLabel key={ category.id } value={ category.id } control={ <Radio color="primary" /> }
                            label={ `${ category.description } - ${ category.name }`} style={{ marginBottom: 10 }}
                        />
                    )) }
                </RadioGroup>
            </FormControl>
        </StepLayout>
    )
}

export default Category