import { SUBSCRIPTION_SETTINGS } from '@constants/general'
import dayjs from 'dayjs'

function getCountdown() {
    const current = dayjs()
    const diffSeconds = current.isBefore(SUBSCRIPTION_SETTINGS.startDate) ? SUBSCRIPTION_SETTINGS.startDate.diff(current, 'seconds') : SUBSCRIPTION_SETTINGS.endDate.diff(current, 'second')

    return {
        days: Math.floor(diffSeconds / 86400),
        hours: Math.floor((diffSeconds % 86400) / 3600),
        minutes: Math.floor(((diffSeconds % 86400) % 3600) / 60),
        seconds: Math.floor((((diffSeconds % 86400) % 3600) % 60))
    }
}

export default getCountdown