import React, { useRef } from 'react'
import Button from '@mui/material/Button'
import { StepLayout } from '../components'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'
import { authUnderAge } from '@assets/files'
import { useDispatch, useSelector } from 'react-redux'
import { changeValue } from '@pagesComponents/subscription/actions'
import { FilePreview } from '@pagesComponents/subscription'
import { validateFiles } from '@pagesComponents/subscription/utils'

const UnderAgeAuth = () => {
    const inputRef = useRef(null)
    const isMobile = useSelector(state => state.sub.isMobile)
    const currentFile = useSelector(state => state.sub.auths.underAge)
    const dispatch = useDispatch()

    const changeFile = file => {
        dispatch(changeValue('underAge', file, 'auths'))
    }

    const handleChangeFile = e => {
        e.preventDefault()

        const validFile = validateFiles(inputRef.current.files, 1, 30)

        if (validFile) changeFile(validFile[0])
        inputRef.current.value = ''
    }

    return (
        <StepLayout title='Precisamos da autorização de um responsável' description='Parece que você é menor de idade, por
            isso vamos precisar de uma autorização assinada por um responsável, abaixo você pode fazer o download da
            autorização, e depois anexar.' image='ill_accept'
        >
            <Button startIcon={ <GetAppRoundedIcon/> } style={{ marginRight: 20, marginBottom: 20 }} variant='outlined'
                size={ isMobile ? 'small' : 'medium' } download='autorizacao-menor-de-idade' target='_blank' href={ authUnderAge }
            >
                Baixar autorização
            </Button>
            <Button startIcon={ <AttachFileRoundedIcon/> } style={{ marginRight: 20, marginBottom: 20 }} variant='outlined'
                size={ isMobile ? 'small' : 'medium'} onClick={ () => inputRef.current.click() }
            >
                { currentFile ? 'Alterar arquivo' : 'Anexar autorização' }
            </Button>
            { currentFile && <FilePreview file={ currentFile } onDelete={ () => changeFile(null) } isMobile={ isMobile }/> }
            <input ref={ inputRef } type='file' accept='application/pdf,image/*' multiple={ false } style={{ display: 'none' }}
                onChange={ handleChangeFile }
            />
        </StepLayout>
    )
}

export default UnderAgeAuth