const styles = {
    button: {
        marginTop: 15,
        marginLeft: 10,
    },
    buttons: {
        display: 'flex',
        width: {
            md: '50%',
            xs: '100%'
        },
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    stepBackground: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 15
    },
    stepTexts: {
        width: { 
            md: '50%',
            xs: '100%'
        }
    },
    stepTitle: {
        mt: 4,
        fontWeight: 'bold'
    },
    stepImage: {
        width: '35%',
    },
    stepDescription: {
        mt: .6,
        mb: 3
    },
}

export default styles