import React, { useEffect } from 'react'
import { Layout } from '@components/organisms'
import { Redirect } from '@reach/router'
import ROUTES from '@constants/routes'
import { IS_SUBSCRIPTION_AVAILABLE } from '@constants/general'
import { PageHeader, DialogSuccess, Stepper } from '@pagesComponents/subscription'
import { useTheme } from "@mui/material/styles"
import useMediaQuery from '@mui/material/useMediaQuery'
import { changeValue, loadDeviceData } from '@pagesComponents/subscription/actions'
import { useDispatch } from 'react-redux'
import { useLocation } from "@reach/router"

const Subscription = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        if (IS_SUBSCRIPTION_AVAILABLE || location.hash === '#dev') dispatch(loadDeviceData())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(changeValue('isMobile', isMobile))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    if (!IS_SUBSCRIPTION_AVAILABLE && location.hash !== '#dev') return <Redirect to={ `${ ROUTES.about.path }#contador` } noThrow/>
    return (
        <Layout transparentAppbar seo={{ title: 'Inscrição', description: 'Inscreva-se no Concurso de Fotografia - Foco no Cooperativismo' }}> 
            <PageHeader/>
            <Stepper/>
            <DialogSuccess/>
        </Layout>
    )
}

export default Subscription