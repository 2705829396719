import { SUB_CHANGE_CHILD_VALUE, SUB_CHANGE_ERROR, SUB_CHANGE_VALUE } from "@constants/actionTypes"
import { isUnderAge, validateCpf, validateStep } from './utils'
import firebase from "gatsby-plugin-firebase"
import { LENGTH, REGEX } from "@constants/inputs"
import dayjs from 'dayjs'
import { getReducer } from 'utils'

export function changeValue(property, payload, parent) {
    return { type: parent ? SUB_CHANGE_CHILD_VALUE : SUB_CHANGE_VALUE, property, payload, parent }
}

export function changeError(parent, payload, add) {
    return { type: SUB_CHANGE_ERROR, parent, payload, add }
}

export function goNextStep(step, isLastStep) {
    return async dispatch => {
        if (isLastStep) {
            dispatch(createSubscription())
            return
        }

        if (!validateStep(dispatch, step)) return

        if (step === 1) {
            dispatch(changeValue('warning', '', 'stepper'))
            dispatch(changeValue('loading', 'Verificando CPF... Aguarde', 'stepper'))

            const identity = getReducer('sub', 'profile', 'cpf')
            try {
                const res = await firebase.app().functions('southamerica-east1').httpsCallable('fnc-identityAvailable')(identity)
                
                if (res.data) {
                    dispatch(changeValue('warning', '', 'stepper'))
                } else {
                    dispatch(changeValue('warning', 'Infelizmente, só aceitamos uma inscrição por pessoa', 'stepper'))
                    dispatch(changeValue('loading', '', 'stepper'))
                    return
                }
            } catch(error) {
                console.error(error)
            }
        }

        dispatch(changeValue('stepper', { index: step + 1 }))
    }
}

export function changeInput(parent, id, value) {
    return dispatch => {
        let error = false
        const digits = value.replace(/\D/g, '')

        switch(id) {
            case 'name': error = value.length < LENGTH.name.min || !value.match(REGEX.name); break
            case 'cpf': error = digits.length < LENGTH.cpf.min || !validateCpf(value); break
            case 'rg':
                value = digits
                error = digits.length < LENGTH.rg.min
                break
            case 'dateOfBirth':
                const dateArr = value.split('-').map(el => parseInt(el, '10')).map(el => isNaN(el) ? -1 : el)
                const d = dateArr[0], m = dateArr[1], y = dateArr[2]
                error = dateArr.length !== 3 || d < 0 || d > 31 || m < 0 || m > 12 || y < 1910 || y > dayjs().year() - 2

                dispatch(changeValue('underAge', isUnderAge(value, 'DD-MM-YYYY'), 'profile'))
                break
            case 'phone': error = digits.length < LENGTH.phone.min || !digits.match(REGEX.phone); break
            case 'address': error = value.length < LENGTH.address.min; break
            case 'email':
                value = value.replace(/ /g, '')
                error = value.length < LENGTH.email.min || !value.match(REGEX.email); break
            case 'title': error = value.length < LENGTH.photoTitle.min ; break
            case 'place': error = value.length < LENGTH.photoPlace.min ; break
            default:
        }

        dispatch(changeValue(id, value, parent))
        dispatch(changeError(parent, id, error))
    }
}

export function loadDeviceData() {
    return async dispatch => {
        try {
            const res = await fetch('https://json.geoiplookup.io/')
            const json = await res.json()

            const data = {
                userAgent: navigator.userAgent,
                browserLanguage: navigator.language,
                browserOnline: navigator.onLine,
                cookieEnabled: navigator.cookieEnabled,
                ip: json.ip,
                org: json.org,
                hostname: json.hostname,
                latitude: json.latitude,
                longitude: json.longitude,
                city: json.city,
                region: json.region,
                district: json.district,
                connectionType: json.connection_type,
                asn: json.asn
            }

            dispatch(changeValue('device', data))
        } catch(error) {
            console.error(error)
        }
    }
}

/**
 * Save the images in a separated doc in storage, to avoid security problems, then send all data to a cloud function to create the subscription
 * 
 * The photo and auths need to be saved before because callable functions have a limit of 5MB of data per call
 */
function createSubscription() {
    return async dispatch => {
        const refDoc = firebase.firestore().collection('modules/fnc/pendingSubscriptions').doc()
        const { auths, profile, device, photo, stepper } = getReducer('sub')
        dispatch(changeValue('stepper', { index: stepper.index, loading: 'Iniciando envio dos arquivos' }))

        const changeMsg = msg => dispatch(changeValue('loading', msg, 'stepper'))

        const data = {
            device,
            key: refDoc.id,
            photo: {
                category: photo.category,
                title: photo.title,
                place: photo.place
            },
            profile: {
                name: profile.name,
                identity: profile.cpf.replace(/\D/g, ''),
                rg: profile.rg.replace(/ /g, ''),
                dateOfBirth: profile.dateOfBirth.replace(/ /g, ''),
                phone: profile.phone.replace(/\D/g, ''),
                address: profile.address,
                email: profile.email.replace(/ /g, ''),
            },
            auths: {}
        }
        
        try {
            if (auths.underAge) {
                data.auths.underAge = await uploadFile(`${ refDoc.path }/auths/under_age`, auths.underAge, val => changeMsg(`Enviando autorização de menor: ${ val }%`))
            }

            if (auths.peoples.length > 0) {
                for (const [index, file] of auths.peoples.entries()) {
                    const newAuth = await uploadFile(`${ refDoc.path }/auths/people_${ index }`, file, val => changeMsg(`Enviando autorização de uso de imagem ${ index + 1 } de ${ auths.peoples.length }: ${ val }%`))
                    data.auths.peoples = data.auths.peoples ? [...data.auths.peoples, newAuth] : [newAuth]
                }
            }

            data.photo.file = await uploadFile(`${ refDoc.path }/photo`, photo.file, val => changeMsg(`Enviando foto: ${ val }%`))

            dispatch(changeValue('loading', 'Validando e salvando dados da inscrição', 'stepper'))

            const res = await firebase.app().functions('southamerica-east1').httpsCallable('fnc-subscribe')(data)
            
            if (res.data.success) {
                dispatch(changeValue('subscriptionID', res.data.key))
                dispatch(changeValue('dialogSuccess', true))
            } else {
                dispatch(changeValue('loading', '', 'stepper'))
                dispatch(changeValue('warning', res.data.error, 'stepper'))
            }
        } catch(error) {
            await deleteFiles(data)
            console.error('Error on subscribe: ', error.code, error)
            const message = error.code === 'storage/unauthorized' ? 'Você pode anexar apenas arquivos de imagem ou PDF' : `Ocorreu um erro: ${ error.message }`

            dispatch(changeValue('loading', '', 'stepper'))
            dispatch(changeValue('error', message, 'stepper'))
        }
    }
}

/**
 * Upload a file to storage
 * 
 * ---
 * 
 * @param { string } ref - Storage reference (Without extension)
 * @param { (File) } file - File to upload
 * @param { function } uploadProgress - When upload progress changes, this function is called passing the current upload progress percentage (0 to 100)
 * @returns { Promise } Returns a promise thats resolve when file is uploaded
 */
function uploadFile(path, file, uploadProgress) {
    return new Promise((resolve, reject) => {
        const ext = file.name.split('.').slice(-1)[0]
        const refFile = firebase.storage().ref(`${ path }.${ ext }`)
        const task = refFile.put(file)

        task.on('state_changed', snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            uploadProgress(progress)
        }, error => {
            reject(error)
        }, () => {
            resolve(refFile.fullPath)
        })
    })
}

/**
 * Delete the files from storage
 * 
 * ---
 * 
 * @param { object } data - Subscription data
 */
async function deleteFiles(data) {
    try {
        if (data.auths.underAge) await firebase.storage().ref(data.auths.underAge).delete()
        if (data.auths.peoples) {
            for (const auth of data.auths.peoples) {
                await firebase.storage().ref(auth).delete()
            }
        }
        if (data.photo.file) await firebase.storage().ref(data.photo.file).delete()
    } catch(error) {
        console.error('Error on delete files: ', error)
    }
}