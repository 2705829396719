import React from 'react'
import Button from '@mui/material/Button'
import { StepLayout } from '../components'
import { useSelector } from 'react-redux'

const Text = props => {
    const { title, button, children, image } = props
    const isMobile = useSelector(state => state.sub.isMobile)

    const handleClickButton = () => {
        window.open(button.path, '_blank')
    }

    return (
        <StepLayout title={ title } description={ children } image={ image }>
            { button &&
                <Button onClick={ handleClickButton } variant='outlined' size={ isMobile ? 'small' : 'medium' }>
                    { button.label }
                </Button>
            }
        </StepLayout>
    )
}

export default Text