const styles = {
    iconButtonPeopleAuth: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    iconPeopleAuth: {
        border: '1px solid #ccc',
        borderRadius: '50%'
    }
}

export default styles