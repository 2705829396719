const styles = {
    background: {
        padding: 25
    },
    title: {
        fontWeight: 'bold',
        mb: 2
    },
    button: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: 10
    },
    subId: {
        backgroundColor: '#e1e1e1',
        marginTop: 6,
        borderRadius: 8,
        padding: 6
    }
}

export default styles